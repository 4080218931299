import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import Question from '../question/Question';
import Survey from '../survey/Survey';
import QuestionData from '../../data/QuestionData';
import AnswerData from '../../data/AnswerData';

export default function App() {
  return (
    <Router>
      <div className="App">
        {/* <nav className="navigation">
          <ul>
            <li>
              <Link to="/">Splash</Link>
            </li>
            <li>
              <Link to="/Rules">Rules</Link>
            </li>
            <li>
              <Link to="/Quiz">Quiz</Link>
            </li>
          </ul>
        </nav> */}

        <Switch>
          <Route path="/Quiz">
            <Quiz />
          </Route>
          <Route path="/Rules">
            <Rules />
          </Route>
          <Route path="/">
            <Splash />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Splash() {
  return (
    <div className="splash-screen">
      <span className="be-inspired"></span>
      <Link className="splash-link" to="/Rules"></Link>
    </div>
  );
}

function Quiz() {
  return (
    <div className="survey">
      <Survey questions = {
                getQuestions(10)
            }/>
    </div>
    );
}

function getQuestions(num){
  var allQuestions = [
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Seuli",false), new AnswerData("Tokio",true), new AnswerData("Stambolli",false)], "tokyo"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Dubai",true), new AnswerData("Moska",false), new AnswerData("Parisi",false)], "dubai"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Londra",false), new AnswerData("Milano",false), new AnswerData("Nju Jorku",true)], "ny"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Milano",false), new AnswerData("Stambolli",true), new AnswerData("Moska",false)], "istanbul"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Parisi",false), new AnswerData("Seuli",true), new AnswerData("Moska",false)], "seul"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Seuli",false), new AnswerData("Stambolli",true), new AnswerData("Hong Kongu",false)], "istanbul"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Parisi",true), new AnswerData("Seuli",false), new AnswerData("Moska",false)], "paris"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Hong Kongu",true), new AnswerData("Moska",false), new AnswerData("Nju Jorku",false)], "hk"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Ankara",false), new AnswerData("Berlini",true), new AnswerData("Roma",false)], "berlin"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Monako",true), new AnswerData("Seuli",false), new AnswerData("Moska",false)], "monako"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Seuli",true), new AnswerData("Berlini",false), new AnswerData("Roma",false)], "seul"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Seuli",false), new AnswerData("Tokio",true), new AnswerData("Stambolli",false)], "tokyo2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Dubai",true), new AnswerData("Moska",false), new AnswerData("Parisi",false)], "dubai2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Londra",false), new AnswerData("Milano",false), new AnswerData("Nju Jorku",true)], "ny2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Milano",false), new AnswerData("Stambolli",true), new AnswerData("Moska",false)], "istanbul2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Parisi",false), new AnswerData("Seuli",true), new AnswerData("Moska",false)], "seul2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Seuli",false), new AnswerData("Stambolli",true), new AnswerData("Hong Kongu",false)], "istanbul2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Parisi",true), new AnswerData("Seuli",false), new AnswerData("Moska",false)], "paris2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Hong Kongu",true), new AnswerData("Moska",false), new AnswerData("Nju Jorku",false)], "hk2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Tokio",false), new AnswerData("Berlini",true), new AnswerData("Roma",false)], "berlin2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Monako",true), new AnswerData("Seuli",false), new AnswerData("Moska",false)], "monako2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Seuli",true), new AnswerData("Berlini",false), new AnswerData("Roma",false)], "seul2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Seuli",false), new AnswerData("Tokio",true), new AnswerData("Stambolli",false)], "tokyo3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Dubai",true), new AnswerData("Moska",false), new AnswerData("Parisi",false)], "dubai3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Londra",false), new AnswerData("Ankara",false), new AnswerData("Nju Jorku",true)], "ny3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Milano",false), new AnswerData("Stambolli",true), new AnswerData("Moska",false)], "istanbul3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Parisi",false), new AnswerData("Seuli",true), new AnswerData("Moska",false)], "seul3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Seuli",false), new AnswerData("Stambolli",true), new AnswerData("Hong Kongu",false)], "istanbul3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Parisi",true), new AnswerData("Seuli",false), new AnswerData("Moska",false)], "paris3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Hong Kongu",true), new AnswerData("Moska",false), new AnswerData("Nju Jorku",false)], "hk3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Tokio",false), new AnswerData("Berlini",true), new AnswerData("Roma",false)], "berlin3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Monako",true), new AnswerData("Seuli",false), new AnswerData("Moska",false)], "monako3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Seuli",true), new AnswerData("Berlini",false), new AnswerData("Roma",false)], "seul3"),

]

  var newQuestions = [];
  while(num > 0){
    newQuestions.push(allQuestions.splice(Math.floor(Math.random() * allQuestions.length), 1)[0]);
    num--;
  }

  return newQuestions;
}

function Rules() {
  return (
  <div className="row rules-of-game">
          <Link className="splash-link" to="/Quiz"></Link>
    <span className="the-rules">
    NE JEMI DUKE BASHKUAR DHE SHPËRBLYER NJERËZIT ME JETË KOZMOPOLITANE.
<br /><br />
DO T’IU SHFAQIM 10 QYTETE; SECILI ME MUNDËSI PERGJIGJIEVE SHUMËFISHTA. MERRË PIKË DUKE QËLLUAR QYTETET E SAKTA.
<br /><br />
SA MË SHUMË PIKË TË QËLLUARA, AQ MË I MIRË DO JETË SHPËRBLIMI.
<br /><br />
ATA QË ARRIJN MË SHUMË (REACH FOR MORE), DO MARRIN SHPËRBLIMIN KRYESOR- AKSESOR TË PËRKRYERA PËR ATA ME STIL JETE KOZMOPOLITANE.
<br /><br />
<b>SUKSESE</b>

    </span>
  </div>
  );
}


