import React from 'react';
import QuestionData from '../../data/QuestionData';
import AnswerData from '../../data/AnswerData';
import Question from '../question/Question';
import {
    Link
  } from "react-router-dom";

class Survey extends React.Component {
    constructor(props) {
        super(props);
        var questionsprops = this.props.questions;
        console.log(questionsprops);
        var singlepropq = questionsprops.splice(Math.floor(Math.random() * questionsprops.length), 1);
        this.state = {
            questions:questionsprops,
            question:singlepropq[0],
            points:0,
            thosequestions:questionsprops
        };

        this.questionAnswered = this.questionAnswered.bind(this);
    }

    questionAnswered(points){
        console.log(points == 0);
        if(points == 0 ) {
            this.setState({question:null});
            return false
        } else {
            var allQuestions = this.state.questions;
            if(allQuestions.length > 0){
                var singleQuestion = allQuestions.splice(Math.floor(Math.random() * allQuestions.length), 1);
                this.setState({questions:allQuestions, question:singleQuestion[0], points: this.state.points + points});
            }else{
                this.setState({question:null, points: this.state.points + points});
                return false;
            }
            return true;
        }
    }

    render(){    
        if(this.state.question != null){
        return(
            <Question ida={this.state.question.imageID} question={this.state.question.questionText} answers={this.state.question.answersArray} onAnswer = {this.questionAnswered}/>
        );
        }else{
            return(
            <div className="osvoivte-poeni">
                <h1 class="end-points">
                    <b> Urime!</b> <br /> <br /> Keni fituar {this.state.points}/1000
                </h1>
                <Link className="splash-link" to="/"></Link>

                </div>);
        }
    }   
}

export default Survey;